module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[],"preloadTags":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Verak Insurance","short_name":"Verak","start_url":"/","display":"standalone","icon":"./src/assets/logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"62bdbb9cc3ec026c5e9b17dc4475c72b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en","hi"],"defaultLanguage":"en","localeJsonSourceName":"locale","siteUrl":"https://verak.in","i18nextOptions":{"defaultNS":"common","debug":false,"lowerCaseLng":true,"saveMissing":false,"interpolation":{"escapeValue":false},"keySeparator":".","nsSeparator":false,"returnEmptyString":true,"react":{"useSuspense":true,"defaultTransParent":"div","transEmptyNodeValue":"","transSupportBasicHtmlNodes":true,"transKeepBasicHtmlNodesFor":[],"transWrapTextNodes":""}},"pages":[{"matchPath":"/privacy","excludeLanguages":["hi"]},{"matchPath":"/refund-policy","excludeLanguages":["hi"]},{"matchPath":"/terms","excludeLanguages":["hi"]},{"matchPath":"/campaigns/facebook","excludeLanguages":["hi"]},{"matchPath":"/campaigns/google","excludeLanguages":["hi"]},{"matchPath":"/campaigns/google1","excludeLanguages":["hi"]},{"matchPath":"/campaigns/google3","excludeLanguages":["hi"]},{"matchPath":"/campaigns/google3v1","excludeLanguages":["hi"]},{"matchPath":"/fos","excludeLanguages":["hi"]},{"matchPath":"/campaigns/google4","includeLanguages":[]},{"matchPath":"/campaigns/google4v1","includeLanguages":[]},{"matchPath":"/business-type","includeLanguages":["hi"]}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
