import React from 'react'
import "./src/styles/standard.css"

import { MixpanelProvider } from "./src/context/MixpanelContext";

export const wrapRootElement = ({ element }) => {
    return (
        <MixpanelProvider>
            {element}
        </MixpanelProvider>
    );
}