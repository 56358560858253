import React from "react";
import mixpanel from "mixpanel-browser";

export const MixpanelContext = React.createContext()

export const useMixpanel = () => {
    const mixpanelContext = React.useContext(MixpanelContext)

    // if (!mixpanelContext) {
	// 	throw new Error('useMixpanel must be used within MixpanelProvider')
	// }
	return mixpanelContext
}

export const MixpanelProvider = ({ children }) => {
    mixpanel.init(process.env.GATSBY_MIXPANEL_PROJECT_TOKEN);

    // console.log("mx initiated...")
    // if (process.env.NODE_ENV !== 'production') {
        console.log('disabled mx')
        mixpanel.disable();
    // }
    
    return (
        <MixpanelContext.Provider value={mixpanel}>
            {children}
        </MixpanelContext.Provider>
    )
}